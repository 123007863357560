import createPalette, {
  PaletteColorOptions,
  PaletteOptions,
} from '@material-ui/core/styles/createPalette';
import createTypography, {
  Typography,
  TypographyOptions,
} from '@material-ui/core/styles/createTypography';
import { ThemeOptions } from '@material-ui/core/styles/createTheme';
import { getOr } from 'lodash/fp';

const colorLightPink = '#EF7EB0';

export interface IPaletteOptionsCustom extends PaletteOptions {
  tertiary: PaletteColorOptions;
  red: PaletteColorOptions;
  blue: PaletteColorOptions;
  cyan: PaletteColorOptions;
  coral: PaletteColorOptions;
  violet: PaletteColorOptions;
  pink: PaletteColorOptions;
}

export interface ICustomThemeOptions extends ThemeOptions {
  customShadows: {
    dropdown: string;
  };
  breakpointXxl: number;
}

export const paletteOptions: IPaletteOptionsCustom = {
  primary: {
    main: '#2CA7DF',
    50: '#DDF1FA',
    100: '#BCE5F8',
    200: '#A0D4ED',
    300: '#85CCEC',
    400: '#58B9E6',
    500: '#2CA7DF',
    600: '#449EC7',
    700: '#4E93B4',
    800: '#3980A0',
    900: '#16698F',
  },
  secondary: {
    main: '#212222',
    50: '#F9FBFD',
    100: '#EDEEEE',
    200: '#D3D5D5',
    300: '#B9BCBC',
    400: '#868989',
    500: '#535656',
    600: '#3A3C3C',
    700: '#212222',
    800: '#121212',
    900: '#000000',
  },
  tertiary: {
    main: colorLightPink,
    50: '#FDEFF5',
    100: '#FAD9E8',
    200: '#F7C2DA',
    300: '#F5ABCC',
    400: colorLightPink,
    500: '#EC67A2',
    600: '#E95194',
    700: '#E73A86',
    800: '#E42378',
    900: '#D31A6C',
  },
  text: {
    primary: 'rgba(33, 34, 34, 1)',
    secondary: 'rgba(33, 34, 34, 0.75)',
    hint: 'rgba(33, 34, 34, 0.50)',
    disabled: 'rgba(33, 34, 34, 0.25)',
  },
  error: {
    main: '#FF1F1F',
    light: '#FF9F9F',
    dark: '#CF0606',
  },
  warning: {
    main: '#FFA800',
    light: '#FFC962',
    dark: '#F09F01',
  },
  info: {
    main: '#2CA7DF',
    light: '#BCE5F8',
    dark: '#16698F',
  },
  success: {
    main: '#60DA74',
    light: '#90E59E',
    dark: '#00AC78',
  },
  background: {
    default: '#eef0f3',
    paper: '#ffffff',
  },
  red: {
    main: '#F92662',
    50: '#ffcccc',
    100: '#FF89B2',
    200: '#FF6BA2',
    300: '#F64E81',
    400: '#F92662',
    500: '#CE2D56',
    600: '#B92D5F',
    700: '#971141',
    800: '#8F1045',
  },
  blue: {
    main: '#0080FF',
    100: '#78BCFF',
    200: '#52A8FE',
    300: '#0080FF',
    400: '#0F52BA',
    500: '#1135A7',
    600: '#010080',
  },
  cyan: {
    main: '#76D5F3',
    100: '#B9E5EB',
    200: '#6BF9F1',
    300: '#87EBE0',
    400: '#3EE1D0',
    500: '#76D5F3',
    600: '#B0DFE5',
    700: '#D7F0F3',
  },
  coral: {
    main: '#FFB3BF',
    50: '#FFF0EC',
    100: '#FFCFC7',
    200: '#FFB3BF',
  },
  violet: {
    main: '#9560EC',
    light: '#D7C5F6',
  },
  pink: {
    main: '#E42378',
    light: colorLightPink,
  },
};

const fontFamily = {
  nunitoSans: 'Nunito Sans',
  publicSans: 'Public Sans',
  sansSerif: 'sans-serif',
};

const typographyOptions: TypographyOptions = {
  fontFamily: [fontFamily.nunitoSans, fontFamily.sansSerif].join(','),
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 700,
  fontWeightBold: 900,
};

export const typography: Typography = createTypography(
  createPalette(paletteOptions),
  typographyOptions,
);

const { pxToRem } = typography;

export const options: ICustomThemeOptions = {
  palette: paletteOptions,
  shape: {
    borderRadius: 4,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1280,
      lg: 1366,
      xl: 1920,
    },
  },
  customShadows: {
    dropdown: `0px 4px 15px ${getOr(
      '#D3D5D5',
      ['secondary', '200'],
      paletteOptions,
    )}`,
  },
  breakpointXxl: 2560,
  typography: {
    ...typographyOptions,
    h1: {
      fontSize: pxToRem(28),
      lineHeight: pxToRem(36),
      fontWeight: typographyOptions.fontWeightBold as number,
      letterSpacing: 0.1,
    },
    h2: {
      fontSize: pxToRem(20),
      lineHeight: pxToRem(27),
      fontWeight: typographyOptions.fontWeightBold as number,
      letterSpacing: 0.1,
    },
    h3: {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
      fontWeight: typographyOptions.fontWeightBold as number,
      letterSpacing: 0.3,
    },
    h4: {
      fontSize: pxToRem(14),
      lineHeight: pxToRem(22),
      fontWeight: typographyOptions.fontWeightMedium as number,
      letterSpacing: 0.1,
    },
    h5: {
      fontFamily: [fontFamily.publicSans, fontFamily.sansSerif].join(','),
      fontSize: pxToRem(12),
      lineHeight: pxToRem(14),
      fontWeight: 500,
      letterSpacing: 0.3,
    },
    h6: {
      fontFamily: [fontFamily.publicSans, fontFamily.sansSerif].join(','),
      fontSize: pxToRem(10),
      lineHeight: pxToRem(14),
      fontWeight: 500,
      letterSpacing: 0.3,
    },
    caption: {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(20),
      fontWeight: typographyOptions.fontWeightBold as number,
    },
    body1: {
      fontFamily: [fontFamily.publicSans, fontFamily.sansSerif].join(','),
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
      fontWeight: typographyOptions.fontWeightRegular as number,
      letterSpacing: 0.1,
    },
    body2: {
      fontFamily: [fontFamily.publicSans, fontFamily.sansSerif].join(','),
      fontSize: pxToRem(14),
      lineHeight: pxToRem(22),
      fontWeight: typographyOptions.fontWeightRegular as number,
    },
    button: {
      fontFamily: [fontFamily.publicSans, fontFamily.sansSerif].join(','),
      fontSize: pxToRem(16),
      lineHeight: pxToRem(20),
      fontWeight: typographyOptions.fontWeightMedium as number,
    },
  },
};
